import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const hash = process.env.VUE_APP_HASH;

const encrypt = (conteudo) => CryptoJS.AES.encrypt(JSON.stringify(conteudo), hash).toString();

const decrypt = (crypted) => {
    if (!crypted) return null;
    let decode = CryptoJS.AES.decrypt(crypted, hash).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decode);
};

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        key: "credbee",
        paths: ["token"],
        storage: {
            getItem: key => decrypt(Cookies.get(key)),
            setItem: (key, value) => Cookies.set(key, encrypt(value), { expires: 3, secure: false }),
            removeItem: key => Cookies.remove(key)
        }
    })],
    state,
    getters,
    mutations,
    actions
})
