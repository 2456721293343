// Outras Categorias
const Boot = () => import("../views/Boot");
const Login = () => import("../views/outras/Login");
const Inicio = () => import("../views/outras/Inicio");
const Error404 = () => import("../views/outras/Error404");

// Clientes
const ClienteFormulario = () => import("../views/clientes/Formulario");
const ClienteIndex = () => import("../views/clientes/Index");

// Empresas
const EmpresaFormulario = () => import("../views/empresas/Formulario");
const EmpresaIndex = () => import("../views/empresas/Index");

// Pacotes
const PacoteFormulario = () => import("../views/pacotes/Formulario");
const PacoteIndex = () => import("../views/pacotes/Index");

// Usuários
const UsuarioFormulario = () => import("../views/usuarios/Formulario");
const UsuarioIndex = () => import("../views/usuarios/Index");
const UsuarioAlterarSenha = () => import("../views/usuarios/AlterarSenha");

//Acompanhamentos
const AcompanhamentoIndex = () => import("../views/inadimplencia/Index");

//Comissões
const ComissaoIndex = () => import("../views/comissao/Index");

//Reanalise
const ReanaliseDeContrato = () => import("../views/reanalise/Index");

//Representantes
const RepresentantesIndex = () => import("../views/representantes/Index");
const RepresentantesFormulario = () => import("../views/representantes/Formulario");

export default [
    {
        path: "/",
        component: Boot,
        children: [
            {
                path: "",
                name: "inicio",
                component: Inicio,
                meta: {},
            },
            {
                path: "login",
                name: "login",
                component: Login,
                meta: {},
            }
        ]
    },
    {
        path: "/usuarios",
        component: Boot,
        children: [
            {
                path: "",
                name: "usuariosIndex",
                component: UsuarioIndex,
                meta: {},
            },
            {
                path: "cadastrar",
                name: "usuariosCadastrar",
                component: UsuarioFormulario,
                meta: {
                    submit: "cadastrar"
                },
            },
            {
                path: "cadastrar/representante/:id(\\d+)",
                name: "usuariosCadastrarRepresentante",
                component: UsuarioFormulario,
                meta: {
                    submit: "cadastrar"
                },
            },
            {
                path: "editar/:id(\\d+)",
                name: "usuariosEditar",
                component: UsuarioFormulario,
                meta: {
                    submit: "editar"
                },
            },
            {
                path: "alterar-senha/:id(\\d+)",
                name: "usuariosAlterarSenha",
                component: UsuarioAlterarSenha,
                meta: {},
            }
        ]
    },
    {
        path: "/empresas",
        component: Boot,
        children: [
            {
                path: "",
                name: "empresasIndex",
                component: EmpresaIndex,
                meta: {},
            },
            {
                path: "cadastrar",
                name: "empresasCadastrar",
                component: EmpresaFormulario,
                meta: {
                    submit: "cadastrar"
                },
            },
            {
                path: "editar/:id(\\d+)",
                name: "empresasEditar",
                component: EmpresaFormulario,
                meta: {
                    submit: "editar"
                },
            }
        ]
    },
    {
        path: "/representantes",
        component: Boot,
        children: [
            {
                path: "",
                name: "representantesIndex",
                component: RepresentantesIndex,
                meta: {},
            },
            {
                path: "cadastrar",
                name: "representantesCadastrar",
                component: RepresentantesFormulario,
                meta: {
                    submit: "cadastrar"
                },
            },
            {
                path: "editar/:id(\\d+)",
                name: "representantesEditar",
                component: RepresentantesFormulario,
                meta: {
                    submit: "editar"
                },
            }
        ]
    },
    {
        path: "/pacotes",
        component: Boot,
        children: [
            {
                path: "",
                name: "pacotesIndex",
                component: PacoteIndex,
                meta: {},
            },
            {
                path: "cadastrar",
                name: "pacotesCadastrar",
                component: PacoteFormulario,
                meta: {
                    submit: "cadastrar"
                },
            },
            {
                path: "editar/:id(\\d+)",
                name: "pacotesEditar",
                component: PacoteFormulario,
                meta: {
                    submit: "editar"
                },
            }
        ]
    },
    {
        path: "/inadimplencia",
        component: Boot,
        children: [
            {
                path: "",
                name: "acompanhamentosIndex",
                component: AcompanhamentoIndex,
                meta: {},
            }
        ]
    },
    {
        path: "/comissao",
        component: Boot,
        children: [
            {
                path: "",
                name: "comissaoIndex",
                component: ComissaoIndex,
                meta: {},
            }
        ]
    },
    {
        path: "/clientes",
        component: Boot,
        children: [
            {
                path: "",
                name: "clientesIndex",
                component: ClienteIndex,
                meta: {},
            },
            {
                path: "cadastrar",
                name: "clientesCadastrar",
                component: ClienteFormulario,
                meta: {
                    submit: "cadastrar"
                },
            },
            {
                path: "editar/:id(\\d+)",
                name: "clientesEditar",
                component: ClienteFormulario,
                meta: {
                    submit: "editar"
                },
            }
        ]
    },
    {
        path: "/reanalise",
        component: Boot,
        children: [
            {
                path: "",
                name: "reanaliseIndex",
                component: ReanaliseDeContrato,
                meta: {},
            }
        ]
    },
    {
        path: '*',
        component: Error404
    }
];
