import Vue from 'vue'
import moment from "moment";

Vue.filter('duasPrimeirasLetras', string => {
    const myArray = String(string).split(" ");
    let letters = '';
    myArray.forEach((el, key) => {
        if (key > 1) {
            return false;
        }
        letters += el.charAt(0).toUpperCase()
    })
    return letters
});

Vue.filter('formataData', date => {
    if (Array.isArray(date) && date.length === 0) return date;
    if (Array.isArray(date) && date.length === 1) return moment(date[0], 'YYYY-MM-DD')
        .format('DD/MM/YYYY');
    if (Array.isArray(date) && date.length > 1) return '[ ' + date.map(data => {
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }).join(', ') + ' ]';

    if (date == null) return date;
    if (typeof date === 'string' && String(date).length < 10) return date;

    const dataFormatada = moment(date, 'YYYY-MM-DD')
        .format('DD/MM/YYYY');

    if (dataFormatada === 'Data inválida' || dataFormatada === 'Invalid date') return date;

    return dataFormatada;
});

Vue.filter('primeiraLetraMaiuscula', string => {
    let upper = string.charAt(0).toUpperCase() + string.slice(1);
    upper = upper.replace('_', ' ');
    upper = upper.match(/[A-Z][a-z]+/g);
    let word = '';
    upper.forEach((el) => {
        if (el == 'id' || el == 'Id' || el == 'ID') {
            return;
        }
        word += el + ' '
    })
    return word.trim()
});

Vue.filter('dinheiro', valor => {
    if (isNaN(Number(valor))) {
        return valor
    }
    return 'R$ ' + valor.toLocaleString('pt-br', {minimumFractionDigits: 2});
});

Vue.filter('porcentagem', valor => {
    return `${parseFloat(valor).toFixed(2)}%`.replace('.', ',')
});

Vue.filter('money', valor => {
    return `${parseFloat(valor).toFixed(2)}`.replace('.', ',')
});

Vue.filter('onlyNumber', valor => {
    return valor.replace(/[\D]/g, "")
});

Vue.filter('formataMes', date => {
    if (date == null) {
        return null;
    }
    let [year, month] = date.split("-");
    return `${month}/${year}`;
});

Vue.filter('formataDataHora', date => {
    if (date == null || String(date).length < 8) {
        return date;
    }
    var [year, month, day] = date.split("-");
    var [iday, time] = day.split(" ")
    var [hour, min] = time.split(":")
    return `${iday}/${month}/${year} ${hour}:${min}`;
});

Vue.filter('formataTitulo', str => {
    if (typeof str != "string") {
        return;
    }
    str = str.replace('id', '');
    str = str.replace(/[^a-zA-Z0-9]/g, ' ');
    str = str.replace(/(^\w{1})|(\s+\w{1})/g, (letra) => letra.toUpperCase());
    return str;
});

Vue.filter('nomeArquivoResumido', str => {
    if (typeof str != "string" || str == null) {
        return str;
    }
    return str.substring(str.lastIndexOf('/') + 1);

});
Vue.filter('textoResumido', str => {
    if (typeof str != "string" || str == null) {
        return str;
    }
    return str.length > 100 ? str.substr(0, 97) + '..' : str;
});

export default Vue;
