import store from "../store/index";
import helpers from "../assets/js/helpers";

const beforeEach = async (to, from, next) => {
    const token = store.getters["getToken"];
    const rotaDestino = to.name;
    const rotaAutenticacao = "login";
    const rotaInicio = "inicio";

    store.commit("SET_LOADING", true);

    if (token) {
        try {
            await store.dispatch("actionObterInformacoes");
            rotaDestino === rotaAutenticacao ? helpers.navegar(rotaInicio) : next();
        } catch (e) {
            if (getAcessoNegado(e)) {
                store.commit("RESET_STATE");
                rotaDestino === rotaAutenticacao ? next() : helpers.navegar(rotaAutenticacao);
            } else {
                store.commit("SET_NOTIFICACAO", {
                    status: true,
                    mensagem: "Sistema Indisponível",
                    categoria: 503
                });
                next().catch();
            }
        } finally {
            store.commit("SET_LOADING", false);
        }
    } else {
        rotaDestino === rotaAutenticacao ? next() : helpers.navegar(rotaAutenticacao);
        store.commit("SET_LOADING", false);
    }
};

const getAcessoNegado = (erro) => helpers.isset(() => erro.response.status) && erro.response.status === 401;

export default beforeEach;