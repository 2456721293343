import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Filters from '@/helpers/filters.js'
import VuetifyConfirm from 'vuetify-confirm'

// PLUGINS
import "./plugins/vuelidate";
import "./plugins/vMask";
import "./plugins/vMoney";
import "./plugins/moment";

// Assets
import "./assets/css/dimensionamentos.css";
import "./assets/css/textos.css";
import "./assets/css/variaveis.css";
import "./assets/css/outros.css";
import "./assets/css/cores.css";

Vue.config.productionTip = false
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Sim',
  buttonFalseText: 'Não',
  color: 'orange',
  title: 'Atenção',
  width: 350,
  property: '$confirm'
})

new Vue({
  router,
  store,
  vuetify,
  Filters,
  render: h => h(App)
}).$mount('#app')
