import autenticacao from "../services/autenticacao";

const actionLogin = async ({ commit }, credenciais) => {
    const response = await autenticacao.login(credenciais);
    commit("SET_TOKEN", response.data.token);
};

const actionLogout = async ({ commit }) => {
    await autenticacao.logout();
    commit("RESET_STATE");
};

const actionObterInformacoes = async ({ commit }) => {
    const response = await autenticacao.obterInformacoes();

    commit("SET_USUARIO_LOGADO", response.data.usuarioLogado);
    commit("SET_USUARIOS", response.data.usuarios);
    commit("SET_PACOTES", response.data.pacotes);
    commit("SET_CONTRATOS", response.data.contratos);
    commit("SET_CLIENTES", response.data.clientes);
    commit("SET_EMPRESAS", response.data.empresas);
};

export default {
    actionLogin,
    actionLogout,
    actionObterInformacoes
};