const getUsuarioLogado = (state) => state.usuarioLogado;

const getUsuarios = (state) => state.usuarios;

const getEmpresas = (state) => state.empresas;

const getPacotes = (state) => state.pacotes;

const getClientes = (state) => state.clientes;

const getContratos = (state) => state.contratos;

const getLoading = (state) => state.loading;

const getNotificacao = (state) => state.notificacao;

const getToken = (state) => state.token;

export default {
    getUsuarioLogado,
    getUsuarios,
    getEmpresas,
    getPacotes,
    getClientes,
    getContratos,
    getLoading,
    getNotificacao,
    getToken
};