export default {
    usuarioLogado: {},
    usuarios: {},
    empresas: {},
    pacotes: {},
    clientes: {},
    contratos: {},
    loading: false,
    notificacao: {
        status: false,
        mensagem: null,
        categoria: null
    },
    token: ""
};