import router from "../../router/index";
import moment from "moment";
import store from "../../store/index";

const navegar = (nomeRota, params) => router.push({ name: nomeRota, params }).catch(_ => { });

const isset = (elemento) => {
    try {
        return typeof elemento() !== 'undefined'
    } catch (e) {
        return false
    }
};

const empty = (elemento) => {
    if (!elemento) return true;
    if ([undefined, null, "0"].includes(elemento)) return true;
    if (Array.isArray(elemento) && !elemento.length) return true;
    if (typeof elemento === "object" && !Object.keys(elemento).length) return true;
    return false;
};

const numericoParaMonetarioBrasil = (numerico) => {
    let regex1 = /^\d{0,9999}(\.\d{1,2})?$/
    let regex2 = /^0{2,}$/;
    if (!regex1.test(numerico) || regex2.test(numerico)) return null;
    return Number(numerico).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const monetarioBrasilParaNumerico = (valorString) => {
    if (typeof valorString !== 'string') return valorString;
    let formatado = valorString.replace(/\./g, "").replace(/,/g, ".");
    let numerico = Number(formatado);
    return numerico || null;
};

const formatarDataBrasil = (dataBanco) => {
    if (empty(dataBanco)) return null;
    let [ano, mes, dia] = [dataBanco.substr(0, 4), dataBanco.substr(5, 2), dataBanco.substr(8, 2)];
    return `${dia}/${mes}/${ano}`;
};

const formatarDataBrasilParaBanco = (dataBrasil) => {
    try {
        return moment(dataBrasil, "DD/MM/YYYY").format("YYYY-MM-DD");
    } catch (error) {
        return null;
    }
};

const montarHeaderToken = () => {
    let token = store.getters["getToken"];
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
};

const validarCnpj = (documento) => {
    documento = String(documento);
    let cnpj = documento.replace(/[^\d]/g, "");

    if (!cnpj || cnpj.length > 14 || /^(\d)\1*$/.test(cnpj)) return false;

    cnpj = cnpj.padStart(14, "0");

    for (var i = 0, j = 5, soma = 0; i < 12; i++) {
        soma += cnpj[i] * j;
        j = (j == 2) ? 9 : j - 1;
    }

    let resto = soma % 11;

    if (cnpj[12] != (resto < 2 ? 0 : 11 - resto)) return false;

    for (var i = 0, j = 6, soma = 0; i < 13; i++) {
        soma += cnpj[i] * j;
        j = (j == 2) ? 9 : j - 1;
    }

    resto = soma % 11;

    return cnpj[13] == (resto < 2 ? 0 : 11 - resto);
};

const validarCpf = (documento) => {
    documento = String(documento);
    let cpf = documento.replace(/[^\d]/g, "");

    if (!cpf || cpf.length > 11 || /^(\d)\1*$/.test(cpf)) return false;

    cpf = cpf.padStart(11, "0");

    for (var t = 9; t < 11; t++) {
        for (var d = 0, c = 0; c < t; c++) {
            d += cpf[c] * ((t + 1) - c);
        }

        d = ((10 * d) % 11) % 10;

        if (cpf[c] != d) return false;
    }

    return true;
};

const validarCep = (cep) => {
    cep = String(cep);
    let regexValida = /^\d{1,5}-\d{3}$/;
    let regexInvalida = /^[0]{1,5}-\d{3}$/;

    return regexValida.test(cep) && !regexInvalida.test(cep);
};

const validarDataBrasil = (data) => {
    if (typeof data !== "string" || data.length !== 10) return false;

    data = String(data);
    let splitData = data.split("/");

    if (splitData.length != 3) return false;

    let [dia, mes, ano] = [parseInt(splitData[0]), parseInt(splitData[1]), parseInt(splitData[2])];
    let diasPorMes = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (isNaN(dia) || isNaN(mes) || isNaN(ano)) return false;

    if (dia <= 0 || mes <= 0 || ano <= 0) return false;

    if (mes > 12) return false;

    if (ano % 4 == 0) {
        diasPorMes[1] = 29;
    }

    if (dia > diasPorMes[mes - 1]) return false;

    return true;
};

const validarUF = (uf) => {
    return [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RO",
        "RS",
        "RR",
        "SC",
        "SE",
        "SP",
        "TO"
    ].includes(uf);
};

const validarDDD = (ddd) => {
    return [
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "21",
        "22",
        "24",
        "27",
        "28",
        "31",
        "32",
        "33",
        "34",
        "35",
        "37",
        "38",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "51",
        "53",
        "54",
        "55",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "71",
        "73",
        "74",
        "75",
        "77",
        "79",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99"
    ].includes(ddd);
};

const validarTelefone = (telefone) => {
    let regex = /^\d{3,5}(-)?\d{4}$/;
    return regex.test(telefone);
};

const validarValorMonetario = (valor) => {
    let regex1 = /^0{2,}$/;
    let regex2 = /^\d{1,9999}(\.\d{1,2})?$/;

    if (regex1.test(valor)) return false;
    if (!regex2.test(valor) || !valor) return false;
    return true;
};

const obterUfs = () => {
    return [
        { id: "AC", label: "Acre" },
        { id: "AL", label: "Alagoas" },
        { id: "AP", label: "Amapá" },
        { id: "AM", label: "Amazonas" },
        { id: "BA", label: "Bahia" },
        { id: "CE", label: "Ceará" },
        { id: "DF", label: "Distrito Federal" },
        { id: "ES", label: "Espírito Santo" },
        { id: "GO", label: "Goiás" },
        { id: "MA", label: "Maranhão" },
        { id: "MT", label: "Mato Grosso" },
        { id: "MS", label: "Mato Grosso do Sul" },
        { id: "MG", label: "Minas Gerais" },
        { id: "PA", label: "Pará" },
        { id: "PB", label: "Paraíba" },
        { id: "PR", label: "Paraná" },
        { id: "PE", label: "Pernambuco" },
        { id: "PI", label: "Piauí" },
        { id: "RJ", label: "Rio de Janeiro" },
        { id: "RN", label: "Rio Grande do Norte" },
        { id: "RS", label: "Rio Grande do Sul" },
        { id: "RO", label: "Rondônia" },
        { id: "RR", label: "Roraima" },
        { id: "SC", label: "Santa Catarina" },
        { id: "SP", label: "São Paulo" },
        { id: "SE", label: "Sergipe" },
        { id: "TO", label: "Tocantins" },
    ];
};

const isString = (conteudo) => conteudo !== undefined && conteudo !== null && conteudo.constructor === String;

export default {
    navegar,
    isset,
    empty,
    numericoParaMonetarioBrasil,
    monetarioBrasilParaNumerico,
    formatarDataBrasil,
    formatarDataBrasilParaBanco,
    montarHeaderToken,
    validarCnpj,
    validarCpf,
    validarCep,
    validarDataBrasil,
    validarUF,
    validarDDD,
    validarTelefone,
    validarValorMonetario,
    obterUfs,
    isString
};
