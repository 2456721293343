import {backend} from "./backend";
import helpers from "../assets/js/helpers";

const login = async ({login, senha}) => {
    let dados = {login, senha};
    let response = await backend.post("api/login", dados);
    return response;
};

const logout = async () => {
    let response = await backend.get("api/logout", helpers.montarHeaderToken());
    return response;
};

const obterInformacoes = async () => {
    let response = await backend.get("api/info", helpers.montarHeaderToken());
    return response;
};

export default {
    login,
    logout,
    obterInformacoes
};