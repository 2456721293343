import Vue from 'vue'
import VueRouter from 'vue-router'

import rotas from "./routes";
import beforeEach from "./beforeEach";

Vue.use(VueRouter)

const routes = [ ...rotas ];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(beforeEach);

export default router;
