import helpers from "../assets/js/helpers";

const SET_USUARIO_LOGADO = (state, payload) => {
    state.usuarioLogado = {
        id: Number(payload.id),
        nome: payload.nome,
        usuario: payload.usuario,
        tipoDocumento: payload.tipo_documento,
        documento: payload.documento,
        email: payload.email,
        ddd: payload.ddd,
        telefone: payload.telefone,
        admin: !!Number(payload.admin),
        habilitado: !!Number(payload.habilitado),
        master: !!Number(payload.master),
        criadoEm: payload.criadoEm,
        atualizadoEm: payload.atualizadoEm,
        empresaId: Number(payload.empresa_id),
    }
};

const SET_USUARIOS = (state, payload) => {
    state.usuarios = [];

    if (helpers.empty(payload)) return;

    payload.forEach(usuario => {
        state.usuarios.push({
            id: Number(usuario.id),
            nome: usuario.nome,
            usuario: usuario.usuario,
            tipoDocumento: usuario.tipo_documento,
            documento: usuario.documento,
            email: usuario.email,
            ddd: usuario.ddd,
            telefone: usuario.telefone,
            admin: !!Number(usuario.admin),
            habilitado: !!Number(usuario.habilitado),
            master: !!Number(usuario.master),
            criadoEm: usuario.criado_em,
            atualizadoEm: usuario.atualizado_em,
            empresaId: Number(usuario.empresa_id)
        });
    });
};

const SET_EMPRESAS = (state, payload) => {
    state.empresas = [];

    if (helpers.empty(payload)) return;

    payload.forEach(empresa => {
        state.empresas.push({
            id: Number(empresa.id),
            razaoSocial: empresa.razao_social,
            cnpj: empresa.cnpj,
            habilitado: !!Number(empresa.habilitado),
            cep: empresa.cep,
            logradouro: empresa.logradouro,
            logradouro_numero: empresa.logradouro_numero,
            complemento: empresa.complemento,
            bairro: empresa.bairro,
            cidade: empresa.cidade,
            uf: empresa.uf,
            ddd: empresa.ddd,
            telefone: empresa.telefone,
            tipo_conta: empresa.tipo_conta,
            conta: empresa.conta,
            agencia: empresa.agencia,
            banco: empresa.banco,
            doc_contrato_social: empresa.doc_contrato_social,
            doc_creci: empresa.doc_creci,
            doc_responsavel: empresa.doc_responsavel,
            criadoEm: empresa.criado_em,
            atualizadoEm: empresa.atualizado_em
        });
    });
};

const SET_CONTRATOS = (state, payload) => {
    state.contratos = [];

    if (helpers.empty(payload)) return;

    payload.forEach(contrato => {
        state.contratos.push({
            id: Number(contrato.id),
            nomeArquivo: contrato.nome_arquivo,
            clicksignId: contrato.clicksign_id,
            clicksignErros: contrato.clicksign_erros,
            clicksignStatus: contrato.clicksign_status,
            clicksignUrl: contrato.clicksign_url_documento,
            pagarmeId: contrato.pagarme_id,
            pagarmePostback: contrato.pagarme_postback,
            pagarmeErro: contrato.pagarme_erro,
            pagarmeStatus: contrato.pagarme_status,
            criadoEm: contrato.criado_em,
            atualizadoEm: contrato.atualizado_em,
            expiraEm: contrato.expira_em,
            clienteId: Number(contrato.cliente_id)
        });
    });
};

const SET_PACOTES = (state, payload) => {
    state.pacotes = [];

    if (helpers.empty(payload)) return;

    payload.forEach(pacote => {
        state.pacotes.push({
            id: Number(pacote.id),
            nome: pacote.nome,
            tipoPagamento: pacote.tipo_pagamento,
            porcentagem: pacote.porcentagem,
            quantidadeParcelas: Number(pacote.quantidade_parcelas),
            parcelasGarantia: Number(pacote.parcelas_garantia),
            parcelasReforma: Number(pacote.parcelas_reforma),
            comissaoTaxaSetup: Number(pacote.comissao_taxa_setup),
            comissaoPacote: Number(pacote.comissao_pacote),
            habilitado: !!Number(pacote.habilitado),
            excluido: !!Number(pacote.excluido),
            criadoEm: pacote.criado_em,
            atualizadoEm: pacote.atualizado_em,
            excluidoEm: pacote.excluido_em,
            taxasSetup: pacote.taxas_setup,
        });
    });
};

const SET_CLIENTES = (state, payload) => {
    state.clientes = [];

    if (helpers.empty(payload)) return;

    payload.forEach(cliente => {
        if(!cliente.venda_atual) return;
        state.clientes.push({
            id: Number(cliente.id),
            nome: cliente.nome,
            email: cliente.email,
            cpf: cliente.cpf,
            rg: cliente.rg,
            dataNascimento: cliente.dt_nascimento,
            ddd: cliente.ddd,
            telefone: cliente.telefone,
            cep: cliente.cep,
            numeroResidencia: cliente.numero_residencia,
            logradouro: cliente.logradouro,
            bairro: cliente.bairro,
            cidade: cliente.cidade,
            uf: cliente.uf,

            venda_atual: cliente.venda_atual,
            valorAluguel: Number(cliente.venda_atual.valor_aluguel),
            valorCondominio: Number(cliente.venda_atual.valor_condominio),
            valorAgua: Number(cliente.venda_atual.valor_agua),
            valorLuz: Number(cliente.venda_atual.valor_luz),
            valorIptu: Number(cliente.venda_atual.valor_iptu),
            taxaSetup: Number(cliente.venda_atual.taxa_setup),

            cadastroAprovado: !!Number(cliente.cadastro_aprovado),
            criadoEm: cliente.criado_em,
            atualizadoEm: cliente.atualizado_em,
            empresaId: Number(cliente.empresa_id),
            pacoteId: Number(cliente.pacote_id),
            logSerasa: cliente.log_serasa,
            cepLocacao: cliente.cep_locacao,
            numeroResidenciaLocacao: cliente.numero_residencia_locacao,
            logradouroLocacao: cliente.logradouro_locacao,
            bairroLocacao: cliente.bairro_locacao,
            cidadeLocacao: cliente.cidade_locacao,
            ufLocacao: cliente.uf_locacao,
            statusId: cliente.status_id,
            contrato_imobiliaria: cliente.contrato_imobiliaria,
            vistoria_imobiliaria: cliente.vistoria_imobiliaria,

            complemento: cliente.complemento,
            complementoLocacao: cliente.complemento_locacao,
            tipoContrato: cliente.tipo_contrato,
        });
    });
};

const SET_LOADING = (state, payload) => {
    state.loading = !!Number(payload);
};

const SET_NOTIFICACAO = (state, payload) => {
    state.notificacao.status = payload.status;
    state.notificacao.mensagem = payload.mensagem;
    state.notificacao.categoria = payload.categoria;
};

const RESET_NOTIFICACAO = (state) => {
    state.notificacao.status = false;
    state.notificacao.mensagem = null;
    state.notificacao.categoria = null;
}

const SET_TOKEN = (state, payload) => {
    state.token = payload;
};

const RESET_STATE = (state) => {
    state.usuarioLogado = null;
    state.usuarios = null;
    state.empresas = null;
    state.pacotes = null;
    state.clientes = null;
    state.loading = null;
    state.token = null;
};

export default {
    SET_USUARIO_LOGADO,
    SET_USUARIOS,
    SET_EMPRESAS,
    SET_CONTRATOS,
    SET_PACOTES,
    SET_CLIENTES,
    SET_LOADING,
    SET_NOTIFICACAO,
    RESET_NOTIFICACAO,
    SET_TOKEN,
    RESET_STATE
};
