import axios from "axios";
import store from "@/store";

let url = process.env.VUE_APP_URLBASE;

export const backend = axios.create({
    baseURL: url,
    timeout: 15000,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
});

export const multipart = axios.create({
    baseURL: url,
    timeout: 15000,
    headers: {
        "Accept": "multipart/form-data",
        "Content-Type": "multipart/form-data"
    }
})


// Interceptor request
backend.interceptors.request.use(config => {
    store.commit('SET_LOADING', true)
    return config
}, (error) => {
    store.commit('SET_LOADING', false)
    return Promise.reject(error);
});

// Interceptor response
backend.interceptors.response.use(resp => {
    store.commit('SET_LOADING', false)
    return resp
}, (error) => {
    store.commit('SET_LOADING', false)
    return Promise.reject(error);
});

export default {
    backend,
    multipart
}
